import { Input, InputNumber, message, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectHwcTouChengProducts,
  selectShowTouChengProductModal,
  selectTouChengProductModalLoading
} from '../../../redux/hwc/hwcTouChengSlice';
import { HwcProduct } from '../../../custom_types/hwc/hwc.product';
import { HwcTouChengProductData } from '../../../custom_types/redux-types';

interface HwcTouChengProductModalProps {
  onCancel: () => void;
  onChange: (products: HwcTouChengProductData[]) => void;
}

const HwcTouChengProductModal = ({ onCancel, onChange }: HwcTouChengProductModalProps) => {
  const loading = useSelector(selectTouChengProductModalLoading);
  const showTouChengProductModal = useSelector(selectShowTouChengProductModal);
  const products = useSelector(selectHwcTouChengProducts);
  const [dataSource, setDataSource] = useState<HwcTouChengProductData[]>([]);
  const [selectedData, setSelectedData] = useState<HwcTouChengProductData[]>([]);

  useEffect(() => {
    const data = products.map(
      (product: HwcProduct): HwcTouChengProductData => ({
        ck_sku: product.ck_sku,
        pname: product.pname,
        zxh: '',
        nums: 0,
        price: 0
      })
    );
    setDataSource(data);
  }, [products]);

  const validateData = (data: HwcTouChengProductData[]): { flag: boolean; msg: string } => {
    const invalidItem = data.find(
      (item) => item.zxh === '' || item.nums === 0 || item.weight === 0
    );

    if (invalidItem) {
      if (invalidItem.zxh === '') {
        return { flag: false, msg: `${invalidItem.ck_sku} - 装箱号不能为空` };
      }
      if (invalidItem.nums === 0) {
        return { flag: false, msg: `${invalidItem.ck_sku} - 单箱数量不能为空` };
      }
      if (invalidItem.weight === 0) {
        return { flag: false, msg: `${invalidItem.ck_sku} - 重量不能为空` };
      }
    }

    return { flag: true, msg: '' };
  };

  const onOKHandler = () => {
    console.log('onOKHandler');
    if (selectedData.length === 0) {
      message.error('请选择商品');
    } else {
      const result = validateData(selectedData);
      if (result.flag) {
        onChange(selectedData);
      } else {
        message.error(result.msg);
      }
    }
  };

  const updateDataSource = (row: HwcTouChengProductData) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.ck_sku === item.ck_sku);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      dataIndex: 'index',
      render: (_: any, __: HwcTouChengProductData, index: number) => index + 1
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '产品名称',
      key: 'pname',
      dataIndex: 'pname'
    },
    {
      title: '装箱号(必须)',
      key: 'zxh',
      dataIndex: 'zxh',
      render: (_: any, record: HwcTouChengProductData) => (
        <Input
          style={{ width: 100 }}
          placeholder="装箱号"
          onChange={(e) => {
            record.zxh = e.target.value;
            updateDataSource(record);
          }}
        />
      )
    },
    {
      title: '单箱数量(必须)',
      key: 'nums',
      dataIndex: 'nums',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          placeholder="单箱数量"
          onChange={(value: number | null) => {
            record.nums = value === null ? 0 : value;
            updateDataSource(record);
          }}
          defaultValue={0}
        />
      )
    },
    {
      title: '单价',
      key: 'price',
      dataIndex: 'price',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          defaultValue={0}
          placeholder="单价"
          onChange={(value: number | null) => {
            record.price = value === null ? 0 : value;
            updateDataSource(record);
          }}
        />
      )
    },
    {
      title: '重量(lb-必须)',
      key: 'weight',
      dataIndex: 'weight',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          defaultValue={0}
          placeholder="重量"
          onChange={(value: number | null) => {
            record.weight = value === null ? 0 : value;
            updateDataSource(record);
          }}
        />
      )
    },
    {
      title: '长(in)',
      key: 'length',
      dataIndex: 'length',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          defaultValue={0}
          placeholder="长"
          onChange={(value: number | null) => {
            record.length = value === null ? 0 : value;
            updateDataSource(record);
          }}
        />
      )
    },
    {
      title: '宽(in)',
      key: 'width',
      dataIndex: 'width',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          defaultValue={0}
          placeholder="宽"
          onChange={(value: number | null) => {
            record.width = value === null ? 0 : value;
            updateDataSource(record);
          }}
        />
      )
    },
    {
      title: '高(in)',
      key: 'height',
      dataIndex: 'height',
      render: (_: any, record: HwcTouChengProductData) => (
        <InputNumber
          min={0}
          defaultValue={0}
          placeholder="高"
          onChange={(value: number | null) => {
            record.height = value === null ? 0 : value;
            updateDataSource(record);
          }}
        />
      )
    }
  ];

  return (
    <Modal
      closable={false}
      title={<div>添加商品</div>}
      cancelText="取消"
      onCancel={onCancel}
      okText="确定"
      okButtonProps={{ loading }}
      onOk={onOKHandler}
      open={showTouChengProductModal}
      width={1500}
    >
      <Table<HwcTouChengProductData>
        title={() => <div color="red">PS:修改商品数据请重新勾选</div>}
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            setSelectedData(selectedRows);
          }
        }}
      />
    </Modal>
  );
};

export default HwcTouChengProductModal;
