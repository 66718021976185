import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import {
  createHwcFulfillOrderHandler,
  fetchFulfillStockHandler,
  selectHwcFulfillOrdersLoading,
  selectHwcShowFulfillStockModal,
  setShowFulfillStockModal
} from '../../../redux/hwc/hwcFulfillOrderSlice';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';
import { selectHwcClientWarehouses } from '../../../redux/hwc/hwcProductSlice';
import {
  HwcFulfillOrder,
  HwcFulfillOrderData,
  HwcFulFillProduct
} from '../../../custom_types/hwc/hwc.fulfillorder';
import { Country, COUNTRY_NAMES } from '../../../shared/utils/constants';
import HwcFulfillStocksModal from '../components/HwcFulfillStocksModal';

const HwcFulfillOrderCreatePage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ckCode = Form.useWatch('ck_nums', form);
  const loading = useSelector(selectHwcFulfillOrdersLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const showFulfillStockModal = useSelector(selectHwcShowFulfillStockModal);
  //   const [
  //     selectHwcClientWarehouse,
  //     setSelectHwcClientWarehouse
  //   ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);
  const [orderProducts, setOrderProducts] = React.useState<HwcFulFillProduct[]>([]);

  useEffect(() => {
    dispatch(fetchFulfillStockHandler(hwcClientWarehouseList[0].id));
    form.resetFields();
  }, [form, dispatch, hwcClientWarehouseList]);

  //   const handleHwcClientWarehouseChange = (id: string) => {
  //     const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
  //     if (clientWarehouse) {
  //       dispatch(fetchFulfillStockHandler(clientWarehouse.id));
  //       setSelectHwcClientWarehouse(clientWarehouse);
  //     }
  //   };

  const addFulfillStockHandler = () => {
    const warehouse = hwcClientWarehouseList.find((item) => item.code === ckCode);
    if (warehouse) {
      dispatch(fetchFulfillStockHandler(warehouse.id));
      dispatch(setShowFulfillStockModal(true));
    }
  };

  const closeFulfillStockModal = () => {
    dispatch(setShowFulfillStockModal(false));
  };

  const modalChangeHandler = (products: HwcFulFillProduct[]) => {
    const newList = [...orderProducts];
    const result = newList.concat(products);
    console.log(result);
    setOrderProducts(result);
    dispatch(setShowFulfillStockModal(false));
  };

  const removeProductItem = (index: number) => {
    const newList = [...orderProducts];
    newList.splice(index, 1);
    setOrderProducts(newList);
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcFulfillOrderData = {
          ck_nums: values.ck_nums, // 仓库编码
          deliver_no: '', // 发货单号 orderId
          chqd: values.chqd, // 物流渠道
          country: values.country, // 国家
          state: values.state, // 州
          city: values.city, // 市
          zip: values.zip, // 邮编
          address: values.address, // 地址
          contact: values.contact, // 联系人
          mobile: values.mobile, // 手机号
          phone: values.phone || '', // 电话号码
          email: values.email || '', // 邮箱
          platform: values.platform || '', // 销售平台/出货类型
          remark: values.remark, // 备注
          deliver_products: orderProducts
        };
        if (data.ck_nums && data.deliver_products.length > 0) {
          dispatch(
            createHwcFulfillOrderHandler(
              data,
              hwcClientWarehouseList.find((item) => item.code === data.ck_nums) === undefined
                ? undefined
                : hwcClientWarehouseList.find((item) => item.code === data.ck_nums)!.id,
              form,
              setOrderProducts
            )
          );
        } else {
          message.error('请填写完整信息');
        }
      })
      .catch((error) => {
        // console.log(error.errorFields[0].errors[0]);
        message.error(error.errorFields[0].errors[0]);
      });
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcFulFillProduct, index: number) => index + 1
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '发货数量',
      key: 'nums',
      dataIndex: 'nums'
    }
  ];

  return (
    <>
      {showFulfillStockModal && (
        <HwcFulfillStocksModal onChange={modalChangeHandler} onCancel={closeFulfillStockModal} />
      )}
      <PageHeader title="新建代发订单" />
      <Form form={form}>
        <Divider orientation="left">发货申请</Divider>
        <Row>
          <Space size="large">
            <Form.Item
              label="代发仓库"
              name="ck_nums"
              rules={[{ required: true, message: '请选择代发仓库' }]}
              initialValue={hwcClientWarehouseList[0].code}
            >
              <Select
                style={{ width: 280 }}
                options={hwcClientWarehouseList.map((item) => ({
                  label: item.name,
                  value: item.code
                }))}
                //   onChange={handleHwcClientWarehouseChange}
              />
            </Form.Item>
            <Form.Item
              label="物流渠道"
              name="chqd"
              rules={[{ required: true, message: '请输入物流渠道' }]}
            >
              <Select
                style={{ width: 280 }}
                options={
                  ckCode
                    ? hwcClientWarehouseList
                        .find((item) => item.code === ckCode)!
                        .channels.map((item) => ({
                          label: item.name,
                          value: item.name
                        }))
                    : []
                }
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="订单备注" name="remark">
              <Input placeholder="订单备注" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item label="销售平台" name="platform">
              <Input placeholder="销售平台" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Divider orientation="left">收件人信息</Divider>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件人名"
              name="contact"
              rules={[{ required: true, message: '请输入收件人名' }]}
            >
              <Input placeholder="收件人名" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件电话"
              name="mobile"
              rules={[{ required: true, message: '请输入收件电话' }]}
            >
              <Input placeholder="收件电话" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件地址"
              name="address"
              rules={[{ required: true, message: '请输入收件地址' }]}
            >
              <Input placeholder="收件地址" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件国家"
              name="country"
              initialValue={Country.USA}
              rules={[{ required: true, message: '请输入收件国家' }]}
            >
              <Select
                style={{ width: 290 }}
                placeholder="请选择收件国家"
                options={[{ label: COUNTRY_NAMES[Country.USA], value: Country.USA }]}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件城市"
              name="city"
              rules={[{ required: true, message: '请输入收件城市' }]}
            >
              <Input placeholder="收件城市" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="州(代码)"
              name="state"
              rules={[{ required: true, message: '请输入州(代码)' }]}
            >
              <Input placeholder="州(代码)" style={{ width: 290 }} maxLength={2} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件邮编"
              name="zip"
              rules={[{ required: true, message: '请输入收件邮编' }]}
            >
              <Input placeholder="收件邮编" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Divider orientation="left">商品信息</Divider>
        <Button
          type="primary"
          onClick={addFulfillStockHandler}
          icon={<PlusOutlined />}
          disabled={ckCode === undefined}
        >
          添加明细
        </Button>
        {orderProducts.length > 0 ? (
          <Table<HwcFulFillProduct>
            rowKey={(record) => record.sku}
            columns={[
              ...columns,
              {
                title: '操作',
                key: 'operations',
                render: (_, __, index: number) => (
                  <Button type="link" onClick={() => removeProductItem(index)}>
                    删除
                  </Button>
                )
              }
            ]}
            dataSource={orderProducts}
          />
        ) : (
          <Empty
            style={{ width: '90%', height: '90%' }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<Typography.Text>还没添加商品，点击添加明细按钮添加商品</Typography.Text>}
          >
            <Button type="primary" onClick={addFulfillStockHandler} disabled={ckCode === undefined}>
              现在添加
            </Button>
          </Empty>
        )}
      </Form>
      <Divider />
      <Space>
        <Button type="primary" onClick={okClickedHandler} loading={loading}>
          保存
        </Button>
        <Button
          onClick={() => {
            form.resetFields();
            setOrderProducts([]);
          }}
          disabled={loading}
        >
          清除
        </Button>
      </Space>
    </>
  );
};

export default HwcFulfillOrderCreatePage;
