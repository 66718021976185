import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Select, Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { render } from '@testing-library/react';
import {
  fetchHwcFulfillOrdersHandler,
  selectHwcFulfillOrders,
  selectHwcFulfillOrdersLoading
} from '../../../redux/hwc/hwcFulfillOrderSlice';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';
import {
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses
} from '../../../redux/hwc/hwcProductSlice';
import { HwcFulfillOrder } from '../../../custom_types/hwc/hwc.fulfillorder';

const HwcFulfillOrderPage = () => {
  const dispatch = useDispatch();
  const hwcFulfillOrders = useSelector(selectHwcFulfillOrders);
  const loading = useSelector(selectHwcFulfillOrdersLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);

  useEffect(() => {
    dispatch(fetchHwcClientWarehousesHandler());
    dispatch(fetchHwcFulfillOrdersHandler(hwcClientWarehouseList[0].code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);
      dispatch(fetchHwcFulfillOrdersHandler(clientWarehouse.code));
    }
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: '订单日期',
      key: 'addtime',
      dataIndex: 'addtime',
      render: (addtime: Date): ReactElement => {
        return (
          <>
            <div>{dayjs(addtime).format('YYYY/MM/DD')}</div>
            <div>{dayjs(addtime).format('HH:mm:ss')}</div>
          </>
        );
      }
    },
    {
      title: '订单号',
      key: 'deliver_no',
      dataIndex: 'deliver_no'
    },
    {
      title: '仓库编码',
      key: 'ck_nums',
      dataIndex: 'ck_nums'
    },
    {
      title: '物流渠道',
      key: 'chqd',
      dataIndex: 'chqd'
    },
    {
      title: '收件人',
      key: 'recepient',
      dataIndex: 'recepient',
      render: (_: any, record: HwcFulfillOrder): ReactElement => {
        return (
          <>
            <div>
              <strong>{record.contact}</strong>
            </div>
            <div>{record.mobile}</div>
            {record.email && <div>{record.email}</div>}
            <div>{record.address}</div>
            <div>
              {record.city} {record.state} {record.zip} {record.country}
            </div>
          </>
        );
      }
    },
    {
      title: '商品信息',
      key: 'products',
      dataIndex: 'products',
      render: (_: any, record: HwcFulfillOrder): ReactElement => {
        return (
          <>
            {record.deliver_products.map((product) => (
              <div key={product.sku}>{`${product.sku} x ${product.nums}`}</div>
            ))}
          </>
        );
      }
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (status: string): ReactElement => {
        return (
          <>
            {status === '1' && <div>待确认</div>}
            {status === '2' && <div>待发货</div>}
            {status === '3' && <div>已打包</div>}
            {status === '4' && <div>已发货</div>}
            {status === '5' && <div>审核不通过</div>}
            {status === '6' && <div>已签收</div>}
            {status === '0' && <div>草稿箱</div>}
            {status === '9' && <div>错误</div>}
          </>
        );
      }
    }
  ];

  return (
    <div>
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Select
              defaultValue={selectHwcClientWarehouse.code}
              style={{ width: 200 }}
              options={hwcClientWarehouseList.map((item) => ({
                label: item.name,
                value: item.code
              }))}
              onChange={handleHwcClientWarehouseChange}
            />
          )
        }
        extra={[
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() => dispatch(fetchHwcFulfillOrdersHandler(selectHwcClientWarehouse.code))}
          >
            同步订单
          </Button>
        ]}
      />
      <Table<HwcFulfillOrder>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={hwcFulfillOrders}
        loading={loading}
      />
    </div>
  );
};

export default HwcFulfillOrderPage;
